import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled from 'styled-components';

const RichTextContainer = styled.span`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  word-break: break-word;
  flex-direction: column;
  overflow: hidden;
  & a {
    color: #007bff;
    text-decoration: underline;
  }
`;

function RichTextRender({ content, style }) {
  return content ? (
    <RichTextContainer style={style}>{parse(content)}</RichTextContainer>
  ) : null;
}

RichTextRender.propTypes = {
  content: PropTypes.string.isRequired,
  style: PropTypes.object,
};

RichTextRender.defaultProps = {
  style: {},
};
export default RichTextRender;
