import EmptyPage from 'components/ui/EmptyPage';

function RouteNotFoundPage() {
  return (
    <EmptyPage
      header="Le lien que vous avez suivi n'existe plus."
      body="Nous sommes navrés pour ce désagrément."
      centered
    />
  );
}

export default RouteNotFoundPage;
