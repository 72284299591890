import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SPINNER_SIZE = '9px';
const SPINNER_WIDTH = '3px';

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: ${SPINNER_WIDTH} solid grey;
  border-right: ${SPINNER_WIDTH} solid grey;
  border-bottom: ${SPINNER_WIDTH} solid grey;
  border-left: ${SPINNER_WIDTH} solid
    ${({ primary, theme }) => (primary ? theme.fontColorPrimary : 'black')};
  background: transparent;
  width: ${SPINNER_SIZE};
  height: ${SPINNER_SIZE};
  border-radius: 50%;
`;

export default Spinner;
