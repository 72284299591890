import commonPropTypes from 'tools/commonPropTypes';
import * as svars from 'style/variables';
import { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

const StarIcon = () => (
  <svg viewBox="0 0 275 275" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <path
      fill="currentColor"
      d="M150,25 L179,111 L269,111 L197,165 L223,251 L150,200 L77,251 L103,165 L31,111 L121,111 Z"
    />
  </svg>
);

const HeartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="currentColor"
  >
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
  </svg>
);

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
  }
  35% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Shape = styled.span`
  font-size: 2.5rem;
  cursor: pointer;
  color: ${({ active, shape }) =>
    active ? (shape === 'star' ? '#ffe234' : '#ff4545') : svars.colorGreyLight};
  padding: ${svars.spaceNormal} 0;
  transition: ${svars.transitionFaster};
  animation: ${({ clicked, selected }) =>
    clicked || selected
      ? css`
          ${pulse} 0.4s ease-in-out
        `
      : 'none'};
  svg {
    width: 100%;
    min-width: 1.5rem;
    max-width: 3.5rem;
    height: auto;
    min-height: 1.5rem;
    max-height: 3.5rem;
    fill: currentColor;
  }
`;

function RatingQuestion({ question, answer, questionValue, focusNext }) {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const shapes = Array.from({ length: question.n_elements }, (_, i) => i + 1);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  const handleClick = (index) => {
    setClickedIndex(index);
    answer(index + 1);
    focusNext();
  };

  return (
    <RatingContainer>
      {shapes.map((shape, index) => (
        <Shape
          key={index}
          shape={question.shape}
          active={
            (hoverIndex >= 0 && hoverIndex >= index) ||
            (hoverIndex < 0 && questionValue > index)
              ? '1'
              : null
          }
          selected={questionValue > index}
          hovered={hoverIndex >= index}
          clicked={clickedIndex >= index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(index)}
        >
          {question.shape === 'star' ? <StarIcon /> : <HeartIcon />}
        </Shape>
      ))}
    </RatingContainer>
  );
}

RatingQuestion.propTypes = {
  ...commonPropTypes.surveyQuestionPropTypes,
  question: commonPropTypes.ratingQuestion.isRequired,
};

export default RatingQuestion;
