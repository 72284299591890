import commonPropTypes from 'tools/commonPropTypes';
import TextArea from 'components/ui/input/TextArea';
import * as svars from 'style/variables';
import { TextInputWithIcon } from 'components/ui/input/TextInput';
import configurationUiService from 'services/configuration.service';

function TextQuestion({ question, answer, isLoading, questionValue }) {
  const {
    wording: { commons },
  } = configurationUiService;
  return (
    (question.short_text && (
      <TextInputWithIcon
        fluid
        autoFocus
        icon={
          questionValue?.length ? (
            <span
              style={{
                fontSize: svars.fontSizeXSmall,
                color: svars.fontColorLighter,
              }}
            >
              {questionValue?.length}/150
            </span>
          ) : null
        }
        disabled={isLoading}
        value={questionValue}
        onChange={({ target: { value } }) => answer(value)}
        required={question.required}
        style={{
          margin: 0,
          width: '100%',
          maxWidth: svars.textMaxWidth,
          fontSize: svars.fontSizeMedium,
          paddingRight: '3rem',
          alignSelf: 'stretch',
        }}
        maxLength={150}
        placeholder={commons.textInputPlaceholder}
        data-testid="fb-question-text-input"
      />
    )) || (
      <TextArea
        placeholder={commons.textInputPlaceholder}
        autoFocus
        disabled={isLoading}
        value={questionValue}
        onChange={({ target: { value } }) => answer(value)}
        required={question.required}
        style={{
          width: '100%',
          minHeight: '10rem',
          maxWidth: svars.textMaxWidth,
          fontSize: svars.fontSizeMedium,
        }}
        maxLength={2000}
        data-testid="fb-question-text-area"
      />
    )
  );
}

TextQuestion.propTypes = {
  ...commonPropTypes.surveyQuestionPropTypes,
  question: commonPropTypes.textQuestion.isRequired,
};

export default TextQuestion;
