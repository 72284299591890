import { autoClickButtonFactory } from 'components/ui/clickable/AutoclickButton';
import Button from 'components/ui/clickable/Button';
import { defaultTheme } from 'style/theme';
import makeTheme from 'style/theme/constructor';
import * as svars from 'style/variables';

const WORDING_COMMONS = {
  fr: {
    close: 'Fermer',
    helpTitle: 'Aide & questions fréquentes',
    faq: 'FAQ',
    privacyPolicy: 'Politique de confidentialité',
    cgu: "Conditions d'utilisation",
    seeMore: 'Voir plus',
    noTagResults: 'Pas de résultat',
    exemples: 'Exemple',
    topic: 'Thème',
    completed: 'complété(s)',
    mandatory: 'obligatoire',
    consultFAQCTA: 'Consultez la FAQ.',
    consultPrivacyPolicyCTA: 'Consultez la politique de confidentialité.',
    consultFAQPrefix: 'Des questions ?',
    sendSurveyCta: 'Envoyer',
    whenContributionSentence: [
      'Lors de votre contribution, vous acceptez la',
      'politique de confidentialité.',
    ],
    textInputPlaceholder: 'Entrez votre réponse ici',
    emptySurveyHeader: "La campagne n'est pas prête",
    emptySurveyBody:
      'Nous sommes désolés pour le désagrément, vous pouvez revenir plus tard pour participer.',
  },
  en: {
    close: 'Close',
    helpTitle: 'Help & frequently asked questions',
    faq: 'FAQ',
    privacyPolicy: 'Privacy policy',
    cgu: 'Terms of use',
    seeMore: 'See more',
    noTagResults: 'No results',
    exemples: 'Example',
    topic: 'Topic',
    completed: 'completed',
    mandatory: 'mandatory',
    consultFAQCTA: 'Check the FAQ.',
    consultPrivacyPolicyCTA: 'Check the privacy policy.',
    consultFAQPrefix: 'Do you have any questions?',
    sendSurveyCta: 'Send',
    whenContributionSentence: [
      'By contributing, you accept the',
      'privacy policy.',
    ],
    textInputPlaceholder: 'Type your answer here',
    emptySurveyHeader: 'The campaign is not ready',
    emptySurveyBody:
      'We apologize for the inconvenience. You can come back later to participate.',
  },
  zh: {
    close: '关闭',
    helpTitle: '帮助与常见问题',
    faq: '常见问题',
    privacyPolicy: '隐私政策',
    cgu: '使用条款',
    seeMore: '查看更多',
    noTagResults: '无结果',
    exemples: '例子',
    topic: '主题',
    completed: '完成',
    mandatory: '必答',
    consultFAQCTA: '查看常见问题解答。',
    consultPrivacyPolicyCTA: '查看隐私政策。',
    consultFAQPrefix: '有疑问吗？',
    sendSurveyCta: '发送',
    whenContributionSentence: ['在您的贡献中，您接受', '隐私政策。'],
    textInputPlaceholder: '在此输入您的答案',
    emptySurveyHeader: '活动尚未准备好',
    emptySurveyBody: '对于造成的不便，我们深感抱歉。您可以稍后再来参与。',
  },
};

// Sort method to sort the list of objects by their position attribute
const compareObjectsPositions = (a, b) => {
  if (a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
};

const indexFaq = (items) => {
  return items.sort(compareObjectsPositions);
};

const primary = '#007DDF';

const makeRawTheme = (primary) => ({
  name: 'Default',
  primary,
  // secondary: '#FFE970',
  fontColorBase: svars.fontColorBase,
  fontColorPrimary: '#FFFFFF',
  others: {
    appBackground: 'linear-gradient(to top, #dde5ef -10%, white 105%)',
    borderRadius: '6px',
    // choiceBackground: '#FFFFFF',
    // choiceBackgroundOdd: '#FFFFFF',
    choiceBoxShadow: svars.baseBoxShadow,
    choiceHoverTextColor: '#FFFFFF',
    // sentimentRangeBackground: 'white',
    // tagBackgroundColorSecondary: primary,
    // tagSecondaryTextColor: '#FFFFFF',
    inputBorderColorFocus: primary,
    iconBackground: '#E9E9E9',
  },
});

// We use a service because it is a simplified redux store and the data stocked here is static
// during one session (It is only loaded at the SPA loading)
class CampaignCommonConfiguration {
  constructor() {
    this.id = null;
    this.language = 'fr';
    this.theme = defaultTheme;
    this.CTAComponent = Button;
    this.displayBwLogo = true;
    this.logoUrl = null;
    this.wording = {
      commons: WORDING_COMMONS[this.language],
    };
    this.faqItems = [];
    this.confidentiality = {
      organizationEmail: null,
      organizationPolicyUrl: null,
    };
  }
  get dump() {
    return JSON.stringify(this);
  }

  load(dump, isSurvey) {
    this.isSurvey = isSurvey;
    this.isFeedbackCampaign = !isSurvey;
    this.id = dump.id;
    this.language = dump.language;
    this.wording = {
      commons: WORDING_COMMONS[this.language],
    };
    this.displayHomeScreen = dump.display_home_screen;
    if (dump.theme) {
      const rawTheme = makeRawTheme(dump.theme.primary || primary);
      const updatedTheme = {
        ...rawTheme,
        ...dump.theme,
        others: { ...rawTheme?.others, ...dump.theme?.others },
      };
      this.theme = makeTheme(
        updatedTheme.name,
        updatedTheme.primary,
        updatedTheme.fontColorBase,
        updatedTheme.fontColorPrimary,
        updatedTheme.relaxed,
        updatedTheme.others
      );
      if (dump.theme.auto_navigate) {
        this.CTAComponent = autoClickButtonFactory(
          dump.theme.navigation_speed || 400
        );
      }
    }
    if (dump.logo_url) {
      this.logoUrl = dump.logo_url;
    }
    if (dump.display_bw_logo !== null) {
      this.displayBwLogo = dump.display_bw_logo;
    }
    if (dump.faq_items) {
      this.faqItems = indexFaq(dump.faq_items);
    }
    if (dump.confidentiality) {
      if (dump.confidentiality.dpo_email) {
        this.confidentiality.organizationEmail = dump.confidentiality.dpo_email;
      }
      if (dump.confidentiality.organization_policy_url) {
        this.confidentiality.organizationPolicyUrl =
          dump.confidentiality.organization_policy_url;
      }
    }
  }
  setTheme(value) {
    this.theme = value;
  }
}

const campaignCommonConfigurationService = new CampaignCommonConfiguration();

export default campaignCommonConfigurationService;
