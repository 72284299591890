import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import { withAnalytics } from 'app/analytics';
import Spinner from 'components/ui/animation/Spinner';
import * as svars from 'style/variables';

const UserAgentButtonCss = css`
  /* Inherited from user agent stylesheet, to have same style for button and link buttons */
  font-variant-ligatures:;
  font-variant-caps:;
  font-variant-numeric:;
  font-variant-east-asian:;
  font-variant-alternates:;
  font-variant-position:;
  font-weight:;
  font-stretch:;
  font-family:;
  font-optical-sizing:;
  font-kerning:;
  font-feature-settings:;
  font-variation-settings:;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin: 0em;
  padding-block: 1px;
  padding-inline: 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
  text-decoration: none;
`;

const BaseButton = styled.button`
  ${UserAgentButtonCss}
  flex-shrink: 0;
  min-height: 37px;
  min-width: 85px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  padding: 6px 12px;
  margin: 0;
  font-weight: ${svars.fontWeightSemiBold};
  font-size: ${({ secondary }) => (secondary ? svars.fontSizeBase : 'inherit')};
  border-radius: ${svars.borderRadius};
  border: ${svars.borderStyle}
    ${({ transparent, disabled, secondary, theme }) =>
      (transparent && 'transparent') ||
      (secondary && disabled && theme.primaryDisabled) ||
      (secondary && theme.primary) ||
      'transparent'};
  cursor: ${({ disabled, done, success }) =>
    (done && 'default') ||
    (disabled && 'not-allowed') ||
    (success && 'default') ||
    'pointer'};
  transition: ${svars.transitionBase};
  background: ${({ theme, disabled, secondary, transparent, success }) =>
    (transparent && 'transparent') ||
    (success && svars.colorSuccess) ||
    (secondary && theme.choiceButton.secondary.background) ||
    (disabled &&
      (theme.choiceButton.disabled.background || theme.primaryDisabled)) ||
    theme.primary};
  color: ${({ theme, transparent, secondary, disabled }) =>
    (secondary && disabled && theme.primaryDisabled) ||
    (transparent && theme.primary) ||
    (secondary && theme.primary) ||
    theme.fontColorPrimary};
  ${({ disabled }) => (disabled ? 'opacity: 0.4;' : '')}

  &:hover {
    color: ${({ theme, secondary, disabled }) =>
      (disabled && secondary && theme.primaryDisabled) ||
      (disabled && theme.fontColorPrimary) ||
      (secondary && theme.choiceButton.secondary.hover.color) ||
      theme.fontColorPrimaryHover};
    background: ${({ theme, disabled, secondary, success, done }) =>
      (done && 'transparent') ||
      (success && svars.colorSuccess) ||
      (disabled && secondary && 'transparent') ||
      (disabled && theme.primaryDisabled) ||
      (secondary && theme.choiceButton.secondary.hover.background) ||
      theme.primaryHovered};
  }
  &:focus {
    box-shadow: ${({ theme: { input } }) => input.focus.boxShadow};
  }
`;

BaseButton.defaultProps = { secondary: false };

const Button = ({ loading, children, ...props }) => (
  <BaseButton loading={loading} {...props}>
    {loading ? (
      <>
        <Spinner primary style={{ marginRight: svars.spaceNormal }} />
        {children}
      </>
    ) : (
      children
    )}
  </BaseButton>
);

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
};
Button.defaultProps = { gaCategory: null, gaAction: null, gaLabel: null };

function BaseLinkButton({ loading, children, ...props }) {
  return (
    <BaseButton as="a" {...props}>
      {loading ? (
        <>
          <Spinner primary style={{ marginRight: svars.spaceNormal }} />{' '}
          {children}
        </>
      ) : (
        children
      )}
    </BaseButton>
  );
}

BaseLinkButton.propTypes = {
  ...Button.propTypes,
};
BaseLinkButton.defaultProps = {
  ...Button.defaultProps,
};

export const LinkButton = withAnalytics(BaseLinkButton);
const AnalyticsButton = withAnalytics(Button);
export default AnalyticsButton;
