import { useMemo } from 'react';
import commonPropTypes from 'tools/commonPropTypes';
import DatePicker from 'components/ui/input/Datepicker';

import * as svars from 'style/variables';
import campaignCommonConfigurationService from 'services/configuration.service';

function DateQuestion({
  focusNext,
  question,
  questionValue,
  answer,
  isLoading,
}) {
  const { language } = campaignCommonConfigurationService;
  const [minDate, maxDate] = useMemo(
    () => [
      question.min_date ? new Date(question.min_date) : null,
      question.max_date ? new Date(question.max_date) : null,
    ],
    [question.min_date, question.max_date]
  );

  return (
    <DatePicker
      language={language}
      value={questionValue}
      onBlur={focusNext}
      onChange={answer}
      required={question.required}
      minDate={minDate}
      maxDate={maxDate}
      disabled={isLoading}
      style={{
        width: '100%',
        maxWidth: svars.textMaxWidth,
        fontSize: svars.fontSizeMedium,
      }}
      data-testid="fb-question-date-picker"
    />
  );
}

DateQuestion.propTypes = {
  ...commonPropTypes.surveyQuestionPropTypes,
  question: commonPropTypes.dateQuestion,
};

export default DateQuestion;
