import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import Button from 'components/ui/clickable/Button';

import * as svars from 'style/variables';

const fillAnimation = keyframes`
  0% { background-position: 100% 50%; }
  100% { background-position: 0 50%; }
`;

const StyledButton = styled(Button)`
  background: ${({ disabled, success, secondary, theme }) =>
    disabled
      ? theme.choiceButton.disabled.background || theme.primaryDisabled
      : `linear-gradient(270deg, ${
          theme.choiceButton.disabled.background || theme.primaryDisabled
        } 50%, ${
          (success && svars.colorSuccess) ||
          (secondary && theme.choiceButton.secondary.background) ||
          theme.primary
        } 50%)`};
  background-size: 200% 100%;
  color: white;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: ${({ speed }) => `background ${speed}ms`};

  &:hover {
    background-position: 0 50% !important;
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.primary} 0%,
      ${({ theme }) => theme.choiceButton.hover?.background} 20%
    );
  }

  ${({ speed, disabled, trigger }) => {
    return (
      !disabled &&
      trigger &&
      css`
        animation: ${fillAnimation} ${speed}ms linear forwards;
      `
    );
  }}
`;

export const autoClickButtonFactory = (navigationSpeed) => {
  function AutoclickButton({
    triggerClick,
    disabled,
    onClick,
    children,
    ...props
  }) {
    const [key, setKey] = useState(Math.random());
    const [triggerEffect, setTriggerEffect] = useState(false);
    const buttonRef = useRef(null);
    const timerRef = useRef(null);
    useEffect(() => {
      clearTimeout(timerRef.current);
      if (!disabled && triggerClick) {
        setTriggerEffect(true);
        timerRef.current = setTimeout(() => {
          onClick();
        }, navigationSpeed);
      } else if (triggerEffect) {
        setTriggerEffect(false);
      }
      // Update key so the button is re-rendered and the animation is restarted
      setKey(Math.random());

      return () => clearTimeout(timerRef.current);
    }, [disabled, triggerClick, buttonRef.current]);

    const handleClick = (e) => {
      clearTimeout(timerRef.current);
      if (!disabled) {
        onClick(e);
      }
    };

    return (
      <StyledButton
        key={key}
        speed={navigationSpeed}
        ref={buttonRef}
        {...props}
        disabled={disabled}
        trigger={triggerEffect ? '1' : null}
        onClick={handleClick}
      >
        {children}
      </StyledButton>
    );
  }

  AutoclickButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    triggerClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  AutoclickButton.defaultProps = {
    disabled: false,
    triggerClick: false,
  };
  return AutoclickButton;
};

const AutoclickButton = autoClickButtonFactory(400);

export default AutoclickButton;
