import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as svars from 'style/variables';

const inputVerticalPadding = '0.3rem';

const TextInput = styled.input`
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  background: transparent;
  transition: ${svars.transitionBase};
  color: ${({ theme, disabled }) =>
    disabled ? svars.fontColorDisabled : theme.fontColorBase};
  padding: ${inputVerticalPadding} ${svars.spaceNormal} 0 ${svars.spaceNormal};
  border-width: 2px;
  border-color: ${({ theme, disabled }) =>
    disabled ? svars.fontColorDisabled : theme.input.borderColor};
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;

  &:disabled {
    background-color: ${({ theme }) => theme.input.background};
  }

  &:focus-within,
  &:focus,
  &:active,
  &:hover {
    border-color: ${({ disabled, theme: { input } }) =>
      disabled ? svars.fontColorDisabled : input.hover.borderColor};

    & + span {
      color: ${({ theme: { input }, disabled }) =>
        disabled ? svars.fontColorDisabled : input.hover.borderColor};
    }
  }
`;

TextInput.defaultProps = { disabled: false };

const IconContainer = styled.span`
  position: absolute;
  ${({ left }) => (left && 'left') || 'right'}: 2px;
  bottom: 10%;
  /* color: ${({ theme }) => theme.fontColorBase}; */
`;

const TextInputWithIcon = ({
  icon,
  left,
  fluid,
  style,
  containerStyle,
  ...props
}) => {
  const inputPaddings = {
    paddingRight: svars.fontSizeXLarge,
    paddingLeft: svars.spaceNormal,
  };
  if (left && icon) {
    inputPaddings.paddingLeft = svars.fontSizeXLarge;
    inputPaddings.paddingRight = svars.spaceNormal;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: fluid ? '100%' : 'auto',
        ...containerStyle,
      }}
    >
      <TextInput
        fluid
        style={{
          boxSizing: 'border-box',
          padding: `${inputVerticalPadding} 0`,
          ...inputPaddings,
          ...style,
        }}
        {...props}
      />
      <IconContainer left={left}>{icon}</IconContainer>
    </div>
  );
};

TextInputWithIcon.propTypes = {
  icon: PropTypes.node,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  fluid: PropTypes.bool,
  left: PropTypes.bool,
};
TextInputWithIcon.defaultProps = {
  fluid: false,
  icon: null,
  style: {},
  containerStyle: {},
  left: false,
};

export default TextInput;
export { TextInputWithIcon };
