import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuestion,
  selectQuestionValue,
  answerQuestion,
  selectLoading,
  focusOnNextButton,
  TEXT_QUESTION_TYPE,
  DATE_QUESTION_TYPE,
  MULTICHOICE_QUESTION_TYPE,
  RATING_QUESTION_TYPE,
  NPS_QUESTION_TYPE,
} from 'store/survey';
import { RequiredHeader } from 'components/ui/Header';
import * as svars from 'style/variables';
import styled from 'styled-components';
import FadeInOut from 'components/ui/animation/FadeInOut';

import RichTextRender from 'components/ui/RichTextRender';
import TextQuestion from './TextQuestion';
import DateQuestion from './DateQuestion';
import MultiChoiceQuestion from './MultiChoiceQuestion';
import RatingQuestion from './RatingQuestion';
import NpsQuestion from './NpsQuestion';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceNormal}
    ${svars.spaceMedium};
  height: calc(100% - ${svars.spaceNormal} - ${svars.spaceMedium});
  overflow: hidden;
  animation: fadeIn 0.5s;
  transition: max-height 0.3s ease;
  min-height: 50vh;
  justify-content: center;
`;

const QuestionBody = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: ${svars.textMaxWidth};
  margin: 0 auto;
  padding-bottom: ${svars.spaceMedium};
  overflow: hidden;
`;
function SurveyQuestion() {
  const question = useSelector(selectQuestion);
  const questionValue = useSelector(selectQuestionValue);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const answer = useCallback(
    (value) => {
      dispatch(answerQuestion({ id: question.id, value }));
    },
    [dispatch, answerQuestion, question.id]
  );
  const focusNext = useCallback(() => {
    dispatch(focusOnNextButton());
  }, []);
  const isTextSurveyQuestion = question.type === TEXT_QUESTION_TYPE;
  const QuestionComponent =
    (question.type === TEXT_QUESTION_TYPE && TextQuestion) ||
    (question.type === DATE_QUESTION_TYPE && DateQuestion) ||
    (question.type === MULTICHOICE_QUESTION_TYPE && MultiChoiceQuestion) ||
    (question.type === NPS_QUESTION_TYPE && NpsQuestion) ||
    (question.type === RATING_QUESTION_TYPE && RatingQuestion) ||
    null;
  if (!QuestionComponent) {
    return null;
  }
  return (
    <FadeInOut show style={{ flexGrow: 1 }}>
      <Container>
        <QuestionBody>
          <RequiredHeader
            style={{
              overflow: 'clip auto',
              marginBottom: svars.spaceMedium,
              flexShrink: 0,
              maxHeight:
                (isTextSurveyQuestion &&
                  ((question.shortText && '90%') || '65%')) ||
                '60%',
            }}
            required={question.required}
            data-testid={`question-${question.id}`}
            subheader={
              question.description ? (
                <RichTextRender content={question.description} />
              ) : null
            }
          >
            <RichTextRender content={question.title} />
          </RequiredHeader>
          <QuestionComponent
            question={question}
            questionValue={questionValue}
            answer={answer}
            isLoading={isLoading}
            focusNext={focusNext}
          />
        </QuestionBody>
      </Container>
    </FadeInOut>
  );
}

export default SurveyQuestion;
