import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withAnalytics } from 'app/analytics';
import * as svars from 'style/variables';

const ClickableIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${svars.spaceSmall};
  width: none;
  margin: ${svars.spaceSmall} 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: ${svars.fontSizeXLarge};
  line-height: ${svars.fontSizeXLarge};
  transition: ${svars.transitionBase};
  background: ${svars.colorGreyLightest};
  border-radius: ${({ theme }) => theme.card.borderRadius};

  color: ${({ theme, primary, disabled }) =>
    (primary && ((disabled && theme.primaryDisabled) || theme.primary)) ||
    (disabled && svars.fontColorDisabled) ||
    'inherit'};

  & svg {
    vertical-align: ${({ verticalAlign }) => verticalAlign};
  }

  &:hover {
    & svg {
      transition: ${svars.transitionBase};
      /* Commented out as it creates inconsistencies in transformations */
      transform: ${({ disabled }) => !disabled && 'scale(1.1)'};
    }
  }
`;

const ClickableIcon = ({
  style,
  primary,
  icon,
  disabled,
  onClick,
  verticalAlign,
  testid,
}) => (
  <ClickableIconContainer
    disabled={disabled}
    onClick={!disabled ? onClick : null}
    primary={primary}
    verticalAlign={verticalAlign}
    style={style}
    data-testid={testid}
  >
    {icon}
  </ClickableIconContainer>
);

ClickableIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  verticalAlign: PropTypes.string,
  testid: PropTypes.string,
};
ClickableIcon.defaultProps = {
  verticalAlign: 'middle',
  primary: false,
  disabled: false,
  onClick: () => {},
  style: {},
  testid: null,
};

const AnalyticsClickableIcon = withAnalytics(ClickableIcon);
export default AnalyticsClickableIcon;
