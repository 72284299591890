import PropTypes from 'prop-types';

const commons = {};

const questionPropTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

commons.textQuestion = PropTypes.shape({
  ...questionPropTypes,
  short_text: PropTypes.bool,
});

commons.dateQuestion = PropTypes.shape({
  ...questionPropTypes,
  min_date: PropTypes.string,
  max_date: PropTypes.string,
});

commons.multiChoiceQuestion = PropTypes.shape({
  ...questionPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.oneOf([null]).isRequired,
        PropTypes.any,
      ]),
      icon: PropTypes.node,
    })
  ).isRequired,
  multiple: PropTypes.bool,
  randomize: PropTypes.bool,
  vertical: PropTypes.bool,
});

commons.ratingQuestion = PropTypes.shape({
  ...questionPropTypes,
  n_elements: PropTypes.number.isRequired,
  shape: PropTypes.oneOf(['star', 'heart']).isRequired,
});

commons.npsQuestion = PropTypes.shape({
  ...questionPropTypes,
});

commons.anyQuestion = PropTypes.oneOfType([
  commons.textQuestion,
  commons.dateQuestion,
  commons.multiChoiceQuestion,
  commons.ratingQuestion,
  commons.npsQuestion,
]);

commons.surveyQuestionPropTypes = {
  question: commons.anyQuestion.isRequired,
  questionValue: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  answer: PropTypes.func.isRequired,
  focusNext: PropTypes.func.isRequired,
};

export default commons;
