import EmptyPage from 'components/ui/EmptyPage';
import commonConfigurationService from 'services/configuration.service';

function EmptySurveyPage() {
  const {
    wording: { commons },
  } = commonConfigurationService;
  return (
    <EmptyPage
      header={commons.emptySurveyHeader}
      body={commons.emptySurveyBody}
    />
  );
}

export default EmptySurveyPage;
