const MULTICHOICE_TYPES = ['MultiChoiceSurveyQuestion'];

/**
 * Checks if the given question type is a multichoice type.
 *
 * @param {string} type - The type of the question to check.
 * @returns {boolean} - Returns true if the type is a multichoice type, otherwise false.
 */
export const isMultichoiceQuestionType = (type) =>
  MULTICHOICE_TYPES.includes(type);

/**
 * Checks if a condition is met based on the provided answer.
 *
 * @param {Object} condition - The condition object to check.
 * @param {any} answer - The answer to check against the condition.
 * @returns {boolean} - Returns true if the condition is met, otherwise false.
 */
const checkCondition = (condition, answer) =>
  (condition.type === 'MultiChoiceDisplayCondition' &&
    condition.values.some(({ id }) => answer?.includes(id))) ||
  (condition.type === 'DateDisplayCondition' &&
    (!condition.min_date || condition.min_date <= answer) &&
    (!condition.max_date || condition.max_date >= answer)) ||
  (['NPSDisplayCondition', 'RatingDisplayCondition'].includes(condition.type) &&
    (!condition.min_value || condition.min_value <= answer) &&
    (!condition.max_value || condition.max_value >= answer));

// We use a service because it is a simplified redux store and the data stocked here is static
// during one session (It is only loaded at the SPA loading)
class SurveyCampaignService {
  // end of survey index symbol (static)
  static END = 'end';
  constructor() {
    this.questions = [];
    this.homeScreen = {};
  }
  get dump() {
    return JSON.stringify(this);
  }

  /* shouldDisplayWithConditions - Check if the question should be displayed based on
    the answers and present questions.
   * @param {Object} answers - The answers object
   * @ param {Object} question - The question object
   * @param {Array} candidateQuestions - The questions array
   * @return {boolean} - If the question should be displayed
   */
  shouldDisplayWithConditions(answers, conditions, candidateQuestions) {
    if (conditions?.length) {
      let answer;
      for (const condition of conditions) {
        answer = answers[condition?.question?.id];
        if (
          // Check if conditioned question was in the survey          // (e.g. condition based on hidden questions are not tested)
          (candidateQuestions &&
            !candidateQuestions?.some(
              ({ id }) => id === condition?.question?.id
            )) ||
          checkCondition(condition, answer)
        ) {
          continue;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  /* getNextQuestionIndex - Get the next question index based on the current index and the answers
   * @param {Object} answers - The answers object
   * @param {number} currentIndex - The current question index
   * @param {boolean} goBack - If the user goes backward
   * @return {number} - The next question index
   */
  getNextQuestionIndex(answers, currentIndex, goBack = false) {
    if (currentIndex === SurveyCampaignService.END) {
      return SurveyCampaignService.END;
    }
    const nextIndex = goBack ? currentIndex - 1 : currentIndex + 1;
    if (nextIndex > this.questions?.length - 1) {
      return SurveyCampaignService.END;
    }
    const nextQuestion = this.questions[nextIndex];
    if (
      nextQuestion &&
      !this.shouldDisplayWithConditions(answers, nextQuestion?.conditions)
    ) {
      return this.getNextQuestionIndex(answers, nextIndex, goBack);
    }
    return nextQuestion ? nextIndex : SurveyCampaignService.END;
  }

  getEndScreen(answers) {
    const { endScreens } = this;
    let defaultEndScreen;
    for (const endScreen of endScreens) {
      if (endScreen.default) {
        defaultEndScreen = endScreen;
      } else if (
        this.shouldDisplayWithConditions(answers, endScreen?.conditions)
      ) {
        return endScreen;
      }
    }
    return defaultEndScreen;
  }

  load({ title, questions, end_screens, home_screen = {} }) {
    this.title = title;
    this.questions = questions.map((question) => {
      return {
        ...question,
        options: question.options?.map(({ id, display_name }) => {
          return {
            key: id,
            value: id,
            label: display_name,
          };
        }),
      };
    });
    this.endScreens = end_screens;
    this.homeScreen = home_screen;
    this.firstQuestionIndex = this.getNextQuestionIndex({}, -1);
    this.campaignIsEmpty =
      this.firstQuestionIndex === SurveyCampaignService.END;
  }
}

const surveyCampaignService = new SurveyCampaignService();

export default surveyCampaignService;
