import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as svars from 'style/variables';
import { BiCalendar } from 'react-icons/bi';
import { useCallback } from 'react';

const DATEPICKER_MAX_WIDTH = '200px';
const DATEPICKER_MAX_HEIGHT = '40px';

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${DATEPICKER_MAX_WIDTH};
  height: ${DATEPICKER_MAX_HEIGHT};
  line-height: ${DATEPICKER_MAX_HEIGHT};
  margin: 0 auto;
  cursor: pointer;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.primary};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  color: transparent;
  padding: ${svars.spaceNormal};
  z-index: 1;
  cursor: pointer;

  border-radius: ${({ theme: { input } }) => input.borderRadius};
  border: none;
  opacity: 0;
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    width: ${DATEPICKER_MAX_WIDTH};
    height: ${DATEPICKER_MAX_HEIGHT};
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
`;

const InputContent = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  font-size: ${({ filled }) =>
    filled ? svars.fontSizeXLarge : svars.lineHeightSizeXLarge};

  & svg {
    font-size: ${svars.fontSizeBig};
  }
`;

function DatePicker({ value, minDate, maxDate, onChange, onBlur, language }) {
  const handleDateChange = useCallback(
    (event) => {
      const newDate = event.target.value;
      if (!newDate) {
        onChange('');
        return;
      }
      if ((minDate && newDate < minDate) || (maxDate && newDate > maxDate)) {
        return;
      }

      onChange(newDate);
      onBlur?.();
    },
    [onChange, onBlur, minDate, maxDate]
  );
  const inputProps = {};
  if (minDate) {
    inputProps.min = minDate.toISOString().split('T')[0];
  }
  if (maxDate) {
    inputProps.max = maxDate.toISOString().split('T')[0];
  }
  const onDateInputClick = useCallback((event) => {
    event.preventDefault();
    document.querySelector('input[type="date"]').showPicker();
  }, []);

  const getLocale = (lang) => {
    switch (lang) {
      case 'fr':
        return 'fr';
      case 'en':
        return 'en-GB';
      case 'zh':
        return 'zh';
      default:
        return 'en-GB';
    }
  };

  return (
    <DatePickerWrapper onClick={onDateInputClick}>
      <Input
        type="date"
        // deactivate the keyboard input for the date picker on Edge
        onKeyDown={(e) => e.preventDefault()}
        value={value || ''}
        onChange={handleDateChange}
        {...inputProps}
      />
      <InputContent filled={value ? '1' : null}>
        <BiCalendar style={{ marginRight: svars.spaceNormalLarge }} />
        {value
          ? new Date(value)
              .toLocaleDateString(getLocale(language))
              .replace(/\//g, ' / ')
          : '__ / __ / ____'}
      </InputContent>
    </DatePickerWrapper>
  );
}

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  language: PropTypes.oneOf(['fr', 'en', 'zh']).isRequired,
};

DatePicker.defaultProps = {
  value: null,
  minDate: null,
  maxDate: null,
  onBlur: () => {},
  language: 'en',
};

export default DatePicker;
