import commonPropTypes from 'tools/commonPropTypes';
import MultiChoice from 'components/ui/input/MultiChoice';
import * as svars from 'style/variables';
import styled from 'styled-components';
import breakpoints from 'style/breakpoints';

const ScrollableContainer = styled.div`
  margin: 0;
  overflow: clip auto;
  margin-top: ${svars.spaceMedium};

  @media only screen and (${breakpoints.device.sm}) {
    width: 100%;
  }
`;

function MultiChoiceQuestion({
  question,
  questionValue,
  answer,
  isLoading,
  focusNext,
}) {
  return (
    <ScrollableContainer>
      <MultiChoice
        richText
        horizontal={!question.vertical}
        disabled={isLoading}
        style={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
        onBlur={!question.multiple ? focusNext : MultiChoice.onBlur}
        options={question.options}
        multiple={question.multiple}
        randomize={question.randomize}
        selected={questionValue}
        onChange={answer}
        required={question.required}
      />
    </ScrollableContainer>
  );
}

MultiChoiceQuestion.propTypes = {
  ...commonPropTypes.surveyQuestionPropTypes,
  question: commonPropTypes.multiChoiceQuestion.isRequired,
};

export default MultiChoiceQuestion;
