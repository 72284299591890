import { useHistory } from 'react-router-dom';
import EndScreen from 'components/ui/EndScreen';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pushWithSearchQuery } from 'helpers';
import { selectLastPublished, selectEndScreen } from 'store/survey';
import { selectPreview } from 'store/review';
import RichTextRender from 'components/ui/RichTextRender';

function SurveyPublishedPage() {
  const history = useHistory();
  const {
    show_button,
    button_label,
    button_url,
    description,
    title,
    illustration,
  } = useSelector(selectEndScreen);
  const withPopper = illustration === 'popper';
  const withRaiseHand = illustration === 'raised_hands';

  const lastPublished = useSelector(selectLastPublished);
  const isPreview = useSelector(selectPreview);
  useEffect(() => {
    if (!lastPublished && !isPreview) {
      pushWithSearchQuery('/', history);
    }
  }, [lastPublished]);

  return (
    <EndScreen
      title={title}
      description={
        description ? <RichTextRender content={description} /> : null
      }
      ctaLabel={show_button ? button_label : null}
      ctaUrl={show_button ? button_url : null}
      withRaiseHand={withRaiseHand}
      withPopper={withPopper}
    />
  );
}

export default SurveyPublishedPage;
