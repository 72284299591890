import PropTypes from 'prop-types';
import styled from 'styled-components';
import FadeInOut from 'components/ui/animation/FadeInOut';
import Segment from 'components/ui/Segment';
import MagnifyingGlass from 'components/ui/MagnifyingGlass';
import { FaTimes } from 'react-icons/fa';
import * as svars from 'style/variables';
import { H2 } from './Header';

const AppContainer = styled.div`
  height: ${svars.appContainerHeight};
  display: flex;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  flex-direction: column;
  overflow-y: hidden;
  margin: ${svars.spaceLarge} ${svars.spaceNormalLarge};
`;

const StyledSegment = styled(Segment)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${svars.spaceLarge};
  min-height: 40vh;
`;

function EmptyPage({ header, body, centered }) {
  return (
    <AppContainer centered={centered ? '1' : null}>
      <FadeInOut show>
        <StyledSegment>
          <MagnifyingGlass
            icon={<FaTimes style={{ color: svars.colorDanger }} />}
          />
          <H2 centered>{header}</H2>
          <span style={{ margin: `0 ${svars.spaceMedium}` }}>
            <p>{body}</p>
          </span>
        </StyledSegment>
      </FadeInOut>
    </AppContainer>
  );
}

EmptyPage.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  centered: PropTypes.bool,
};

EmptyPage.defaultProps = {
  centered: false,
};

export default EmptyPage;
